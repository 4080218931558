@use '../variables' as vars

.buttons
    position: absolute
    top: 240px
    right: 4vw

.buttonActive
    background-color: vars.$maincolor
    color: vars.$bgcolor
    font-weight: bold
    
.container
    margin: 2vh auto 40px
    padding: 24vh 0 80px
    height: 96vh
    box-sizing: border-box
    max-width: 60vw
    overflow-y: scroll
    -moz-osx-font-smoothing: grayscale
    scrollbar-width: none
    -ms-overflow-style: none
    &::webkit-scrollbar
        display: none
    

@media only screen and (max-device-width: 430px)
    .buttons
        position: static
        margin: 24vw auto 0
        width: 100%
        display: flex
        justify-content: center

    .container
        padding: 0
        font-size: 0.8rem
        margin-top: 20px
        height: 72vh