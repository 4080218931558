@use '../variables' as vars

.container 
    margin: 12vh 0 10vh
    padding: 20vh 0
    box-sizing: border-box
    height: 84vh
    overflow-x: hidden
    overflow-y: scroll
    -moz-osx-font-smoothing: grayscale
    scrollbar-width: none
    -ms-overflow-style: none
    &::webkit-scrollbar
        display: none

.postArticle
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 50vw
    margin: 0 auto
    a
        color: cyan
        text-shadow: 0 0 10px cyan
        &:hover
            text-shadow: 0 0 10px cyan, 0 0 20px cyan
    
.comments
    width: 50vw
    display: flex 
    flex-direction: column
    justify-content: center
    align-items: center
    margin: 0 auto


.postImage 
    max-width: 40rem
    object-fit: cover
    margin-left: 20px

.postTitle
    font-family: vars.$titlefont
    font-variation-settings: vars.$titlestyle 
    line-height: 1.5
    text-align: center
    letter-spacing: 0.2rem  
    font-size: 2.5rem
    margin-top: 20px
    margin-bottom: 20px
    max-width: 48vw

.post 
    max-width: 50vw
    margin: 0 4vw

.pleaseLoginLine
    font-size: 1.2rem
    text-align: center


.loginLink
    cursor: pointer
    display: inline-block
    color: vars.$maincolor
    text-decoration: none
    text-shadow: vars.$shadow

    &:hover
        text-shadow: vars.$hovershadow

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)
    
    .container 
        margin: 0 0 10vh
        padding: 20vh 0

    .postArticle
        width: 70vw
        font-size: 1rem

    .comments
        width: 70vw

    .postImage
        max-width: 24rem
        margin-left: 10px

    .postTitle
        font-size: 1.5rem
        line-height: 1.5
        margin-top: 10px
        margin-bottom: 10px

    .post
        max-width: 70vw
        margin: 0 2vw

    .pleaseLoginLine
        font-size: 1rem

@media only screen and (max-device-width: 430px)

    .postImage
        width: 60vw

    .container
        padding: 4vh
    
    .mainTitle
        font-size: 1.5rem
        margin: 120px 0 20px

    .postTitle
        width: 80vw
        max-width: 80vw
        font-size: 1.2rem
        margin-top: 0

    .post
        min-width: 80vw

    .pleaseLoginLine
        font-size: 0.8rem
        margin: 20px 0 0  !important
        padding: 0
        width: 100vw
        text-align: center

    .content
        p
        font-size: 0.8rem !important
