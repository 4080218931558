@use 'variables' as vars

body 
  font-family: vars.$mainfont
  font-size: 16px
  letter-spacing: 0.05em
  line-height: 1.6
  color: vars.$maincolor
  background-color: vars.$bgcolor
  margin: 0
  padding: 0
  width: 100vw
  max-height: 100vh
  box-sizing: border-box
  overflow-x: hidden !important
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  scrollbar-width: none
  -ms-overflow-style: none 
  &::webkit-scrollbar
    display: none


main 
  padding: 0 20px
  margin: 0 auto
  max-width: 1200px
  max-height: 100vh
  overflow-x: hidden !important
  overflow-y: visible
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  scrollbar-width: none 
  -ms-overflow-style: none 
  box-sizing: border-box
  &::webkit-scrollbar
    display: none

header 
  background-color: vars.$bgshade
  padding: 20px
  border-bottom: 1px solid vars.$maincolor
  text-align: center

  h1 
    color: vars.$maincolor
    text-shadow: 0 0 10px vars.$maincolor
    margin: 0
    font-size: 2.5em

    a:visited
      color: vars.$maincolor !important

  nav
    a
      color: vars.$maincolor
      text-decoration: none
      text-shadow: 0 0 5px vars.$maincolor
      margin: 0 10px
      &:hover
        text-decoration: underline

a
  color: vars.$maincolor
  text-decoration: none
  text-shadow: vars.$shadow
  &:hover
    text-shadow: vars.$hovershadow


ul, li
  list-style-type: none
  padding: 0
  margin: 0

button
  background-color: vars.$bgshade
  color: vars.$maincolor
  font-family: vars.$mainfont
  border: 1px solid vars.$maincolor
  padding: 5px 10px
  margin: 20px
  cursor: pointer
  &:hover
    background-color: vars.$maincolor
    color: vars.$bgcolor
    font-weight: bold

footer
  position: fixed
  bottom: 0
  left: 0
  max-height: 60px
  width: 100vw
  background-color: vars.$bgshade

  border-top: 1px solid vars.$maincolor
  text-align: center
  margin-top: 8px

  p, h3
    margin: 0
    width: 100vw
    margin-block-start: 0
    margin-block-end: 0

article
  line-height: 1.8rem

img
  padding: 2px
  // Use box-shadow to simulate a border with distance from the image
  // Format: box-shadow: h-offset v-offset blur spread color;
  box-shadow: 0 0 20px 3px vars.$blurcolor
  display: block

hr
  border: 0
  border-top: 4px double vars.$maincolor
  margin: 80px auto
  text-align: center
  clear: both
  max-width: 60vw
  width: 60vw

code 
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
  main
    padding: 10px
    
  hr
    margin: 40px auto

@media only screen and (max-device-width: 430px)
  main
    max-height: 80vh
    padding: 0
  hr
    margin: 20px auto


