@use '../variables' as vars
  
.homeMain 
  max-width: 100vw
  min-width: 100vw
  height: 84vh
  margin: 12vh auto 0
  padding: 12vh 0 8vh
  overflow-x: hidden
  overflow-y: visible
  box-sizing: border-box
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  scrollbar-width: none
  -ms-overflow-style: none
  &::webkit-scrollbar
    display: none

.welcomeTitle
  font-size: 1.6rem
  margin: 20px 0
  text-align: center

.welcomeBlock
  width: 60vw
  margin: 20px auto
  display: flex
  flex-direction: row
  justify-content: space-between

.avatarImage 
  width: 240px
  margin: 8px 20px 0
  display: block

.welcomeText
  font-size: 1rem
  margin: 0
  padding: 0 0 0 40px
  text-align: justify
  p
    margin: 0 0 4px

.latestPosts
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin: 0 auto

.postTitle
  line-height: 2
  text-align: center

.postContent
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  max-width: 60vw
  overflow-x: hidden
  overflow-y: visible

.featuredPostImage 
  max-width: 25rem
  object-fit: cover
  margin-left: 20px

.latestPosts
  padding-top: 0px
  margin-top: 0px
  
.postTitle
  font-family: vars.$titlefont
  font-variation-settings: "ital" 0, "wght" 300, "wdth" 400
  font-size: 2em
  margin: 20px 0
  color: vars.$maincolor



@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
  .homeMain
    width: 100vw
    height: 86vh
    margin: 8vh 0 80px
  
  .welcomeBlock
    flex-direction: column
    align-items: center
    justify-content: center
    margin: 20px auto

  .welcomeText
    padding: 0

  .avatarImage
    width: 40vw
    margin: 0 auto 28px

  .featuredPostImage
    margin-left: 0

@media only screen and (max-device-width: 430px)

  .homeMain
    width: 100vw
    margin: 8vh auto 80px

  .welcomeBlock
    flex-direction: column
    align-items: center
    justify-content: center
    margin: 20px auto

  .welcomeTitle
    font-size: 1.2rem
    margin: 0
    text-align: center
  
  .welcomeText
    font-size: 1rem
    margin: 0
    padding: 0
    text-align: left

  .avatarImage
    width: 72vw
    margin: 0 auto
  
  .welcomeText
    width: 72vw
    font-size: 0.8rem
    margin: 20px 0
    text-align: left

  .postContent
    max-width: 80vw
    p
      margin: 0 0 4px
    h1
      font-size: 1rem
      margin: 8px 0
      max-width: 60vw

  .postTitle
    font-size: 1.4rem
    margin: 8px 0
    text-align: center
    width: 100vw

  .postText
    padding: 20px 4px 12px 12px
    font-size: 0.8rem
    line-height: 1.8
    width: 72vw


  .featuredPostImage
    max-width: 20rem
    margin-left: 0
    margin: 20px 0


