@use '../variables' as vars
  
.userModal 
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  background-color: vars.$maincolor
  color: vars.$bgcolor
  padding: 20px
  border-radius: 10px
  z-index: 1000

.closeIcon
  position: absolute
  top: 20px
  right: 32px
  font-size: 1.2rem
  cursor: pointer
  z-index: 10000

.deleteAccount, .changePasswordFeedback
  font-size: 0.8rem
  width: 100vw
  padding: 40px 20px
  z-index: 9999
  p
    margin: 12px 0
    padding: 0
    text-align: center
  button
   float: right
   margin: 12px 40px

.buttons
  display: flex
  justify-content: center
  width: 100%
  padding: 0
  button
    margin: 12px 20px
    background-color: vars.$formshade
    border: none
    border-radius: 0
      
    &:hover
      background-color: vars.$maincolor
      color: vars.$formshade
      cursor: pointer
      border: 1px solid vars.$formshade
      box-styling: content-box

.changePasswordFeedback
  max-width: 50vw

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
  .closeIcon
    z-index: 10000
    

@media only screen and (max-device-width: 430px)
  .closeIcon
    z-index: 10000
    right: 64px



