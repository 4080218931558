@use '../../variables' as vars

.singleSeparator
    width: 20vw
    height: 1vw
    margin: 24px auto 24px


.largeSeparator
    width: 10vw
    height: 0
    border: 1px solid vars.$maincolor
    margin: 24px auto 0

.smallSeparator
    width: 5vw
    height: 0
    border: 1px solid vars.$maincolor
    margin: 40px auto
