.commentsBlock
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    width: 50vw

.comment
    display: flex
    flex-direction: column
    align-items: left
    justify-content: center
    width: 40vw
    margin: 20px auto

.commentsTitle
    font-size: 1.5rem
    margin: 80px 0 40px
    text-align: center

.user
    font-size: 1.2rem
    margin-bottom: 0px

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
    .commentsBlock
        width: 70vw

    .comment
        width: 60vw


@media only screen and (max-device-width: 430px)

    .commentsBlock
        width: 80vw

    .comments
        width: 80vw
    
    .comment
        margin: 0 auto
        min-width: 70vw
        width: 70vw !important
        font-size: 80%
