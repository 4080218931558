@use '../variables' as vars
    
.container
    margin: 0 auto 12vh
    padding: 24vh 0
    box-sizing: border-box
    max-width: 50vw
    height: 96vh
    overflow-x: hidden
    overflow-y: visible
    -moz-osx-font-smoothing: grayscale
    scrollbar-width: none
    -ms-overflow-style: none 
    &::webkit-scrollbar
        display: none

.title 
    font-family: vars.$titlefont
    font-variation-settings: vars.$titlestyle
    text-align: center
    letter-spacing: 0.2rem
    font-size: 3rem
    margin-top: 20px
    margin-bottom: 20px

.avatar
    float: left
    margin: 0 20px 8px 0
    max-width: 300px
    max-height: 300px
    object-fit: cover

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
    .container
        max-width: 70vw

@media only screen and (max-device-width: 430px)
    .container
        max-width: 72vw
        margin: 12vh auto 80px
        padding: 20px 0 40px 4px
        display: flex
        flex-direction: column
        align-items: center

        .avatar
            float: none
            max-width: 200px
            max-height: 200px
            margin: 0 16px 0 0
            object-fit: cover
        .title
            font-size: 1.8rem
            margin-top: 10px
            margin-bottom: 10px
        .presentation
            font-size: 14px
            line-height: 1.6
