@use '../variables' as vars

.head, .headRetracted
    background-image: url("../assets/blog-banner-cropped.png")
    background-size: cover
    background-blend-mode: overlay
    background-position-x: right
    background-position-y: top
    position: fixed
    width: 100vw
    left: 0
    transition: top 0.3s 
    overflow-x: hidden
    height: 16vh
    z-index: 10

.head 
    top: 0
    
.headRetracted
    top: -24vh
    
.welcomeBadge
    min-width: 360px
    max-width: 360px
    display: flex
    flex-direction: row


.title 
    font-family: vars.$titlefont
    font-variation-settings: "ital" 0, "wght" 300, "wdth" 400
    letter-spacing: 0.1em
    text-shadow: 0 0 20px vars.$maincolor
    text-align: left
    padding: 0 0 0 10vw
    font-size: 4vw
    margin: 8px 0 0
    max-width: 60vw


.loginLinks 
    font-family: vars.$titlefont
    font-variation-settings: "ital" 0, "wght" 300, "wdth" 400
    position: absolute
    right: 10vw
    top: 1.5vw
    min-width: 250px
    display: flex
    justify-content: space-between
    align-items: center

.userName
    border: none
    margin: 0
    font-size: 120%
    padding: 0

@media only screen and (min-device-width: 431px) and (max-device-width: 1024px) 

    .head
        height: 10vh
        .title
            font-size: 5vw
            padding: 0 0 0 2vw
        .loginLinks
            top: 3.5vh
            right: 6vw
            min-width: 140px
            flex-direction: column
            justify-content: flex-start
            align-items: center
            max-height: 120px
            overflow: hidden

            .userName
                font-size: 100%

            button
                margin: 0 0 16px
                width: 80px
                &:nth-child(2)
                    margin: 16px 0
                    

    .headRetracted
        height: 10vh
        top: 0
        left: 0
        .title
            font-size: 5vw
            padding: 0 0 0 2vw
        .loginLinks
            top: 3.5vh
            right: 6vw
            min-width: 140px
            flex-direction: column
            justify-content: flex-start
            align-items: center
            max-height: 120px
            overflow: hidden
            .userName
                font-size: 100%
            button
                margin: 0 0 16px
                width: 80px
                &:nth-child(2)
                    margin: 16px 0

    .welcomeBadge
        max-width: 140px
        max-height: 160px
        line-height: 1.5
        font-size: 0.8rem
        display: flex
        flex-direction: column
        flex-wrap: wrap
        justify-content: space-between
        align-items: center
        margin: 0
        button
            margin: 0 0 0 0 !important
            width: 80px
            margin: 0 auto

    .title
        max-width: 98vw



@media only screen and (max-device-width: 430px)
    /* Styles for mobile devices */
    .head
        position: fixed
        top: 0
        padding: 0
        height: 11vh
        width: 100%
        overflow-x: hidden
        h1.title
            display: block
            font-size: 1.5rem
            padding: 12px 0 0 0
            margin: 0
            width: 100%
            min-width: 100%
            text-align: center !important
        .loginLinks
            top: 2vh
            right: 5vw
            min-width: 98vw
            flex-direction: column
            justify-content: space-around
            max-height: 120px
            overflow: hidden
            .userName
                font-size: 100%
            button
                margin: 14px 0 16px
                width: 80px

    .headRetracted
        position: fixed
        padding: 0
        height: 11vh
        width: 100%
        top: 0

        .title
            font-size: 1.5rem
            padding: 12px 0 0 0
            margin: 0
            width: 100%
            min-width: 100%
            text-align: center
        .loginLinks
            top: 2vh
            right: 5vw
            min-width: 98vw
            flex-direction: column
            justify-content: space-around
            max-height: 120px
            overflow: hidden
            .userName
                font-size: 100%
                text-transform: uppercase
            button
                margin: 14px 0 16px
                width: 80px

  


