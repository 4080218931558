@use '../variables' as vars
    
.navigationList
    font-family: vars.$titlefont
    font-variation-settings: "ital" 0, "wght" 300, "wdth" 400
    display: flex
    justify-content: space-between
    list-style: none
    padding: 0 9.5vw 20px
    margin: 0 auto
    width: 80vw
    font-size: 1.2rem
    font-weight: 500
    text-transform: uppercase
    text-align: center

.link
    color: vars.$maincolor
    text-decoration: none
    text-shadow: 0 0 5px vars.$maincolor
    margin: 0 10px
    padding: 10px 5px 5px

    &:hover
        background-color: vars.$maincolor
        color: vars.$bgcolor
        font-weight: bold
        text-decoration: none
        text-shadow: none


.active
    color: vars.$maincolor
    padding: 10px 5px 5px
    text-decoration: underline
    
    &:hover
        background-color: vars.$maincolor
        color: vars.$bgcolor
        font-weight: bold


@media ( hover: none )
    .link
        &:hover
            background-color: vars.$bgcolor
            color: vars.$maincolor
            font-weight: normal
            text-decoration: none
            text-shadow: 0 0 5px vars.$maincolor
            
    .active
        &:hover
            background-color: vars.$bgcolor
            color: vars.$maincolor
            font-weight: normal
            
    
@media only screen and (min-device-width: 431px) and (max-device-width: 1024px) 
    /* Styles for iPads (non-Retina and Retina) */
    .navigationList
        max-width: 70vw
        margin: 0 0 0 0 
        padding: 0 0 0 0
        font-size: 2.5vw



@media only screen and (max-device-width: 430px)
    /* Styles for mobile devices */
    .navigationList
        padding: 0 0 0 0
        margin: 0 auto
        font-size: 1.1rem
        max-width: 95vw
        justify-content: space-around
        .link
            margin: 0 5px
            padding: 5px 5px 5px
            font-size: 0.8rem
            text-shadow: none
            &:hover
                background-color: vars.$maincolor
                color: vars.$bgcolor
                font-weight: bold
                text-decoration: none
                text-shadow: none
        .active
            padding: 5px 5px 5px
            font-size: 0.8rem
            text-decoration: underline
            &:hover
                background-color: vars.$maincolor
                color: vars.$bgcolor
                font-weight: bold
                text-decoration: none
                text-shadow: none
