@use '../variables' as vars
  

.dates
    margin: 40px 0 0

.dateLink
  cursor: pointer
  color: vars.$maincolor
  text-decoration: none
  box-styling: border-box
  display: flex
  flex-direction: row
  align-items: center
  margin: 0 20px 0 0
  padding: 0 0 0 10px

  &:hover
    background-color: vars.$maincolor
    
    text-decoration: none
    
    a
      color: vars.$bgcolor

.chosenDateLink
  cursor: pointer
  background-color: vars.$maincolor
  text-decoration: none
  box-sizing: border-box
  display: flex
  flex-direction: row
  align-items: center
  margin: 0 20px 0 0
  padding: 0 0 0 10px

  a
    color: vars.$bgcolor