@use '../variables' as vars
    
.forgotPasswordForm
    display: flex
    flex-direction: column
    align-items: left
    justify-content: center
    height: 30vh
    width: 30vw
    padding: 0 0 0 60px
    z-index: 1000

.forgotPasswordTitle
    font-size: 1.5rem
    font-weight: 500
    margin-bottom: 0px

.formField 
    width: 80%
    margin: 10px 0
    display: grid
    grid-template-columns: 1fr 2fr

    label
        min-width: 200px
    input
        width: 100%
        background-color: vars.$formshade
        color: vars.$maincolor
        font-family: vars.$mainfont

.buttons 
    display: flex
    justify-content: right
    width: 86%
    padding: 0 


    button
        margin: 12px 20px
        background-color: vars.$formshade
        
        &:hover
            background-color: vars.$maincolor
            color: vars.$formshade
            cursor: pointer
            border: 1px solid vars.$formshade
            box-styling: content-box
        

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
    .forgotPasswordForm
        width: 70vw
        transform: translateX(0)
        font-size: 1.1rem
    
    .buttons
        padding: 2% 0 0 0
        button
            font-size: 1.2rem   

@media only screen and (max-device-width: 430px)
    .forgotPasswordForm
        width: 100vw
        p
            font-size: 0.8rem
            max-width: 80vw