@use '../variables' as vars
    
.changePasswordForm
    display: flex
    flex-direction: column
    align-items: left
    justify-content: center
    height: 30vh
    width: 30vw
    padding: 0 0 0 60px
    z-index: 1000

.changePasswordTitle
    font-size: 1.5rem
    font-weight: 500
    margin-bottom: 0px

.formField 
    width: 100%
    margin: 10px 0
    display: grid
    grid-template-columns: 1fr 2fr
    
    label
        font-size: 0.8rem
        margin-right: 20px
        min-width: 140px

    input
        width: 67% 
        background-color: vars.$formshade
        color: vars.$maincolor
        font-family: vars.$mainfont

.buttons 
    display: flex
    justify-content: right
    width: 86%

    padding: 0 


    button
        margin: 12px 20px
        background-color: vars.$formshade
        
        &:hover
            background-color: vars.$maincolor
            color: vars.$formshade
            cursor: pointer
            border: 1px solid vars.$formshade
            box-styling: content-box
        

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
    .changePasswordForm
        width: 70vw
        transform: translateX(0)
        font-size: 1.2rem
    
    .buttons
        padding: 2% 0 0 0
        button
            font-size: 1.2rem   

@media only screen and (max-device-width: 430px)
    .changePasswordForm
        width: 100vw

    .feedback
        width: 100vw
        margin: 0
        padding: 0
        text-align: center
        transform: translateX(-30px)

    .logoutAfterLoginButton
        width: 40vw
        margin: 20px auto
        transform: translateX(-30px)

