@use '../variables' as vars
    
.userPortal
    width: 90vw
    padding: 40px
    border-radius: none
    margin: 0 auto
    h3
        margin:  12px 0 0px 6px
        padding: 12px 0 0 6px
    h2
        margin: 0 0 12px 10px
    
.buttons
    display: flex
    flex-direction: row
    width: inherit
    margin: 0
    justify-content: space-around
    
    button
        min-width: 80px
        margin: 12px 2px
        background-color: vars.$formshade
        border: none
        &:first-child
            margin: 12px 2px 12px 0
        
        &:hover
            background-color: vars.$maincolor
            color: vars.$formshade
            cursor: pointer
            border: 1px solid vars.$formshade
            box-styling: content-box
        &:active
            background-color: vars.$maincolor
            color: vars.$formshade
            cursor: pointer
            border: 1px solid vars.$formshade
            box-styling: content-box