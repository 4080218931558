@use '../variables' as vars
  
.tagLink
  cursor: pointer
  color: vars.$maincolor
  text-decoration: none
  box-sizing: border-box
  display: flex
  flex-direction: row
  align-items: center
  margin: 0 20px 0 0
  padding: 0 0 0 10px

  &:hover
    background-color: vars.$maincolor
    text-decoration: none
    
    a
      color: vars.$bgcolor


.chosenTagLink
  cursor: pointer
  background-color: vars.$maincolor
  text-decoration: none
  box-sizing: border-box
  display: flex
  flex-direction: row
  align-items: center
  margin: 0 20px 0 0
  padding: 0 0 0 10px

  a
    color: vars.$bgcolor

  
  @media only screen and (max-device-width: 1024px) and (min-device-width: 768px)
    .tagLink
      margin: 0 10px 0 0
      padding: 0 0 0 5px
      font-size: 0.6em

    .chosenTagLink
      margin: 0 10px 0 0
      padding: 0 0 0 5px
      font-size: 0.6em

