@use '../variables' as vars

@media only screen and (max-device-width: 1024px) and (min-device-width: 768px)
    .blogFooter
        max-width: 100vw
        overflow-x: hidden
        min-height: 4vh
        font-size: 1rem
        padding-top: 24px
        font-size: 0.6rem
    
@media only screen and (max-device-width: 430px)
    .blogFooter
        position: fixed
        bottom: 0
        width: 100vw
        max-width: 100vw
        overflow-x: hidden
        height: 4vh
        min-height: 4vh
        font-size: 1rem
        padding-top: 16px
        font-size: 0.6rem

    .copyright
        margin: 0 0 0 0

    .linkToLogin
            margin: 0 20px
            padding: 4px 20px
            border: none

    .welcomeBadge
        width: 90vw
        line-height: 1.5
        font-size: 0.8rem
        display: flex
        flex-direction: row
        flex-wrap: nowrap
        justify-content: space-around
        margin: 0 auto
        font-family: vars.$mainfont
        button
            margin: 0
            padding: 0
            border: none
            font-family: vars.$mainfont

    .welcomeBadgeText
        margin: 0
       