@use '../variables' as vars
  

.mainContainer
  display: grid
  grid-template-columns: 2fr 1fr
  padding: 24vh 12vw 0
  width: 100vw 
  height: 100vh
  box-sizing: border-box
  overflow-x: hidden
  overflow-y: scroll

.mainTitle
  font-size: 2.5rem
  margin: 0
  padding: 0

.postTitle
  font-family: vars.$titlefont
  font-variation-settings: "ital" 0, "wght" 300, "wdth" 400
  letter-spacing: 0.1rem
  font-size: 1.6em
  margin: 20px 0
  color: vars.$maincolor
  
.container
  display: flex
  flex-direction: row
  justify-content: space-between
  position: relative

.posts
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 60vw

  hr
    max-width: 50%

.readMoreLink
  padding: 10px
  
.postImage 
    max-width: 16rem
    object-fit: cover
    margin-left: 40px
    transform: translateX(12px)

.sidebar
  width: 14vw
  margin: 20px 18vw 0 2vw 
  border: 1px solid vars.$maincolor
  align-self: flex-start
  padding: 0 0 20px 20px
  box-shadow: 0 0 20px 3px vars.$blurcolor

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
  .mainContainer
    margin: 20vh 8vh 8vh 8vh

  .postImage
    max-width: 15rem
    margin-left: 10px

  .postTitle
    font-size: 1.2em
    margin: 10px 0

  .sidebar
    width: 100%
    max-width: 240px !important
    font-size: 0.8em
    margin: 10px 0
    padding: 0 20px 20px
    box-shadow: 0 0 10px 3px vars.$blurcolor
    box-sizing: border-box

@media only screen and (max-device-width: 430px)
  .mainContainer
    margin: 15vh 0 8vh 12vw
    padding: 0 0 180px
    font-size: 14px
    line-height: 1.6

  .postTitle
    font-size: 1.2rem

  .postContainer
    width: 80vw

  .postImage
    margin-left: 0

  .posts
    article
      width: 80vw
      min-height: 400px