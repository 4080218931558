@use '../variables' as vars

.backdrop
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 200vh
    background-color: rgba(11, 86, 1, 0.345)
    z-index: 100
