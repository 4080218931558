.main
  min-width: 80vw
  width: 80vw
  height: 100vh
  position: relative
  padding: 24vh 0 0 0
  margin: 0 auto
  z-index: 0
  overflow-x: hidden
  overflow-y: visible
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  scrollbar-width: none 
  -ms-overflow-style: none  
  &::webkit-scrollbar
    display: none

.container 
    position: fixed
    width: 98vw
    top: 0
    left: 0
    margin: 0 
    padding: 0
    box-sizing: border-box

.site
  position: fixed
  width: 100vw
  height: 100vh 
  padding: 0
  margin: 0
  overflow-x: hidden
  overflow-y: visible
  scrollbar-width: none 
  &::webkit-scrollbar
    display: none

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
  .main
    top: 9vh
    padding: 0 0 24vh
    width: 80vw


@media only screen and (max-device-width: 430px) 
  .main
    width: 90vw
    padding: 8vh 0 0 !important
    height: 100vh
    max-height: 100%
  
  .site
    height: 100vh
    max-height: 100vh
    overflow-y: hidden !important