// Variables
$maincolor: #0f0
$blurcolor: #00ff00a1
$bgcolor: #000
$bgshade: #121
$formshade: #083102
$mainfont: 'Kallisto', 'Courier New', Courier, monospace
$titlefont: 'reflex-pro-variable', sans-serif
$titlestyle: "ital" 0, "wght" 300, "wdth" 400
$shadow: 0 0 5px $maincolor
$hovershadow: 0 0 5px $maincolor, 0 0 20px $maincolor