@use '../variables' as vars
    
.commentForm
    display: flex
    flex-direction: column
    justify-content: center
    height: 30vh
    width: 46vw
    padding: 0
    margin: 48px auto 0
    transform: translateX(2vw)

.commentTitle
    font-size: 1.5rem
    font-weight: 500
    margin-bottom: 0px

.formField 
    width: 80%
    margin: 10px 0
    display: grid
    grid-template-columns: 1fr 2fr

    textarea
        width: 110%
        background-color: black
        color: vars.$maincolor
        font-family: vars.$mainfont
        border: 1px solid vars.$maincolor
        min-height: 200px
        &:focus-visible
            outline: none
            border: 1px solid vars.$maincolor
            box-shadow: vars.$hovershadow


.buttons 
    display: flex
    justify-content: right
    width: 86%
    padding: 2% 0 0 0 


    button
        margin: 12px 20px
        background-color: vars.$formshade
        
        &:hover
            background-color: vars.$maincolor
            color: vars.$formshade
            cursor: pointer
            border: 1px solid vars.$formshade
            box-styling: content-box
        
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
    .commentForm
        width: 70vw
        transform: translateX(0)
    
    .formField
        width: 90%
        margin: 10px 0
        display: grid
        grid-template-columns: 1fr 2fr

        textarea
            width: 110%
            background-color: black
            color: vars.$maincolor
            font-family: vars.$mainfont
            border: 1px solid vars.$maincolor
            min-height: 200px
            &:focus-visible
                outline: none
                border: 1px solid vars.$maincolor
                box-shadow: vars.$hovershadow

        