.cookie-consent-overlay
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background-color: rgba(0, 0, 0, 0.7)
    z-index: 9998
    
.cookie-consent
    position: fixed
    bottom: 0 !important
    left: 0
    min-height: 200px
    width: 100%
    color: white
    font-size: 16px
    transition: bottom 0.4s !important
    z-index: 9999 !important
    div
        font-size: 20px

.cookie-consent-hidden
    bottom: -200px !important
    transition: bottom 0.4s !important

.cookie-consent-destroyed
    display: none !important

.no-scroll
    overflow: hidden