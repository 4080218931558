.container
    width: 80vw
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin: 120px auto
    h1, h3
        width: 100%
        text-align: center
    button
        width: 120px
        text-align: center